import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        resources: {
            en: {
                translation: {
                    program: {
                        similarPrograms: "Similar Programs",
                        duration: "Duration",
                        deadline: "Deadline",
                        startDate: "Start date",
                        viewProgram: "View Program",
                        programTuition: "Program Tuition",
                        aboutProgram: "About Program",
                        month: "month",
                        from: "From",
                        billsIncluded: "Bills Included",
                        city: "City",
                        programLanguage: "Program language",
                        languagePrograms: "Language Programs",
                        year: "year",
                        years: "years",
                        Years: "Years",
                        nearbyHousing: "Nearby Housing",
                        uniPlacesLabel: "Powered by UniPlaces",
                        Unknown: "Unknown",
                        noDescription: "No Description",
                        Apply: "Apply",
                        uniPlacesImageAlt: "University location",
                        portuguese: "Portuguese",
                        applyAnytime: "Apply anytime",
                        Submit: "Submit"
                    },
                    deadlineLabels: {
                        initDeadline: "Initial application deadline",
                        second: "Second Round",
                        third: "Third Round",
                        special: "Special Round",
                        initDeadlineSmall: "Initial Application",
                        secondSmall: "Second Round",
                        thirdSmall: "Third Round",
                        fourthSmall: "Fourth / Special Round",
                        fifthSmall: "Fifth / Special Round",
                    },
                    apply: {
                        quickApply: "Quick apply",
                        description: "Begin the application notifying your interest to the program to the university. The university will contact you back with further information on the admissions process.",
                        firstName: "First name",
                        lastName: "Last name",
                        middleNames: "Middle names (optional)",
                        Email: "Email",
                        SelectYourCountry: "Select Your Country",
                        WhatsCitizenship: "What is your citizenship status?",
                        TOS: "I agree to the Terms of Service and Privacy Agreement",
                        marketing: "I agree to offers related to my application",
                        confirm: "The university should get back to you via email soon",
                        Loading: "Loading..."
                    },
                    citizenship: {
                        nonEU: "Non-EU Citizen",
                        EU: "EU Citizen",
                        CPLP: "CPLP Citizen"
                    },
                    home: {
                        notRelative: "Not a relative of an EU member",
                        relative: "Relative of EU member",
                        tuitionNote: "Tuition may be lower if you have a parent or grandparent who's a EU citizen",
                        discoverPortugal: "Discover Portugal",
                        taglinePT1: "Study at any of 2500+ available",
                        taglinePT2: "programs",
                        sintra: "Sintra, Portugal",
                        studyInEnglish: "Study in English",
                        studyInEnglishSubtext: "Discover bachelor's and master's degrees taught in English",
                        learnPortuguese: "Learn Portuguese",
                        learnPortugueseSubtext: "Learn Portuguese at language schools and universities",
                        housing: "Find housing",
                        housingSubtext: "Discover great homes, flats, rooms, and dorms",
                        sponsored: "(Sponsored)",
                        searchADegree: "Search a degree or course...",
                        ImA:"I'm a...",
                        welcome: "Welcome to Study"
                    },
                    navbar: {
                        Universities: "Universities",
                        Contact: "Contact",
                        Home: "Home"
                    },
                    institutions: {
                        subtext: "There are 18 in-person public universities in Portugal, and 8 private universities.",
                        imageAltText: "Coimbra, Portugal background image"
                    },
                    search: {
                        Citizenship: "Citizenship",
                        notRelativeShort: "Non-relative of EU citizen",
                        relative: "Relative of EU citizen",
                        Filters: "Filters",
                        Language: "Language",
                        error404: "Error 404 - Program not found",
                        Degree: "Degree"
                    },
                    languageOptions: {
                        Any: "Any",
                        English: "English",
                        Portuguese: "Portuguese",
                        French: "French"
                    },
                    degreeTypes: {
                        title: "Degree",
                        langProg: "Language Programs",
                        Masters: "Master's",
                        Bachelors: "Bachelor's",
                        DegreeIn: "Degree In",
                        BachelorsIn: "Bachelor's in",
                        MastersIn: "Bachelor's in",
                        PHDIn: "Bachelor's in",
                        FoundationYearIn: "Foundation Year in",
                        Nondegreecourse: "Non degree course",
                        FoundationYear: "Foundation Year",
                        IntegratedMasters: "Integrated Masters",
                        LangP: "Language Program",
                        PHD: "PHD",
                        in: "in"
                    },
                    contact: {
                        title: "Contact us",
                        name: "Name",
                        email: "Email",
                        message: "Message",
                        submit: "Submit",
                        resultMessage: "We usually reply to emails within 4 business days.",
                        resultMessageTitle: "We'll get back to you soon"
                    }
                }
            },
            uk: {
                translation: {
                    program: {
                        similarPrograms: "Схожі програми",
                        duration: "Тривалість",
                        deadline: "Дедлайн",
                        startDate: "Дата початку",
                        viewProgram: "Переглянути програму",
                        programTuition: "Навчання за програмою",
                        aboutProgram: "Про програму",
                        month: "місяць",
                        from: "від",
                        billsIncluded: "Рахунки включені",
                        city: "Місто",
                        programLanguage: "Мова програми",
                        year: "рік",
                        years: "років",
                        Years: "Роки",
                        nearbyHousing: "Житло поблизу",
                        uniPlacesLabel: "Powered by UniPlaces",
                        Unknown: "Невідомо",
                        noDescription: "Немає опису",
                        languagePrograms: "Мовні програми",
                        Apply: "Подати заявку",
                        uniPlacesImageAlt: "Розташування університету",
                        portuguese: "Португальська",
                        applyAnytime: "Подавайте заявку в будь-який час",
                        Submit: "Надіслати"
                    },
                    deadlineLabels: {
                        initDeadline: "Кінцевий термін подачі заявок",
                        second: "Другий раунд",
                        third: "Третій раунд",
                        special: "Спеціальний раунд",
                        initDeadlineSmall: "Початкова заявка",
                        secondSmall: "Другий раунд",
                        thirdSmall: "Третій раунд",
                        fourthSmall: "Четвертий / Спеціальний раунд",
                        fifthSmall: "П'ятий / Спеціальний раунд",
                    },
                    apply: {
                        quickApply: "Швидко подати заявку",
                        description: "Розпочніть процес подачі заявки, повідомивши університет про свій інтерес до програми. Університет зв'яжеться з вами, щоб надати подальшу інформацію щодо процесу вступу.",
                        firstName: "Ім'я",
                        lastName: "Прізвище",
                        middleNames: "По батькові (необов'язково)",
                        Email: "Електронна пошта",
                        SelectYourCountry: "Виберіть свою країну",
                        WhatsCitizenship: "Який у вас статус громадянства?",
                        TOS: "Я погоджуюся з Умовами надання послуг та Угодою про конфіденційність",
                        marketing: "Я погоджуюся з пропозиціями, пов'язаними з моєю заявкою",
                        Next: "Далі"
                    },
                    citizenship: {
                        nonEU: "Не є громадянином ЄС",
                        EU: "Громадянин ЄС",
                        CPLP: "Громадянин CPLP"
                    },
                    home: {
                        notRelative: "Не є родичем громадянина ЄС",
                        relative: "Родич громадянина ЄС",
                        tuitionNote: "Вартість навчання може бути нижчою, якщо у вас є батьки, дідусь чи бабуся, які є громадянами ЄС",
                        discoverPortugal: "Відкрийте для себе Португалію",
                        taglinePT1: "Навчайтеся на будь-якому з 2500+ доступних курсів",
                        taglinePT2: "програми",
                        sintra: "Сінтра, Португалія",
                        studyInEnglish: "Навчання англійською мовою",
                        studyInEnglishSubtext: "Відкрийте для себе бакалаврські та магістерські програми, що викладаються англійською мовою",
                        learnPortuguese: "Вивчайте португальську мову",
                        learnPortugueseSubtext: "Вивчайте португальську в мовних школах та університетах",
                        housing: "Знайти житло",
                        housingSubtext: "Відкрийте для себе чудові будинки, квартири, кімнати та гуртожитки",
                        sponsored: "(Спонсорська підтримка)",
                        searchADegree: "Пошук за ступенем або курсом...",
                        ImA:"Я...",
                        welcome: "Ласкаво просимо на навчання"
                    },
                    navbar: {
                        Universities: "Університети",
                        Contact: "Контакти",
                        Home: "Головна сторінка"
                    },
                    institutions: {
                        subtext: "У Португалії є 18 державних університетів з очною формою навчання та 8 приватних університетів.",
                        imageAltText: "Коїмбра, Португалія фонове зображення"
                    },
                    search: {
                        Citizenship: "Громадянство",
                        notRelativeShort: "Не є родичем громадянина ЄС",
                        relative: "Родич громадянина ЄС",
                        Filters: "Фільтри",
                        Language: "Мова",
                        error404: "Помилка 404 - Програма не знайдена",
                        Degree: "Степень"
                    },
                    languageOptions: {
                        Any: "Будь-яка",
                        English: "Англійська мова",
                        Portuguese: "Португальська мова",
                        French: "Французька мова"
                    },
                    degreeTypes: {
                        title: "Ступінь",
                        langProg: "Мовні програми",
                        Masters: "Магістр",
                        Bachelors: "Бакалавр",
                        DegreeIn: "Ступінь в",
                        BachelorsIn: "Бакалавр в",
                        MastersIn: "Магістр в",
                        PHDIn: "Кандидат технічних наук в",
                        FoundationYearIn: "Рік заснування в",
                        Nondegreecourse: "Недипломний курс",
                        FoundationYear: "Рік базового курсу",
                        IntegratedMasters: "Інтегровані магістри",
                        LangP: "Мовна програма",
                        PHD: "Доктор філософії",
                        in: ""
                    },
                    contact: {
                        title: "Зв'яжіться з нами",
                        name: "Ім'я",
                        email: "Електронна пошта",
                        message: "Повідомлення",
                        submit: "Надіслати",
                        resultMessage: "Зазвичай ми відповідаємо на електронні листи протягом 4 робочих днів",
                        resultMessageTitle: "Ми зв'яжемося з вами найближчим часом"
                    }
                }
            },
            ru: {
                translation: {
                    program: {
                        similarPrograms: "Похожие программы",
                        duration: "Продолжительность",
                        deadline: "Крайний срок",
                        startDate: "Дата начала",
                        viewProgram: "Просмотр программы",
                        programTuition: "Стоимость программы",
                        aboutProgram: "О программе",
                        month: "месяц",
                        from: "От",
                        billsIncluded: "Счета включены",
                        city: "Город",
                        programLanguage: "Язык программы",
                        year: "год",
                        Years: "Годы",
                        years: "лет",
                        nearbyHousing: "Жилье поблизости",
                        uniPlacesLabel: "Работает на UniPlaces",
                        Unknown: "Неизвестный",
                        noDescription: "Нет описания",
                        Apply: "Применить",
                        uniPlacesImageAlt: "Расположение университета",
                        languagePrograms: "Языковые программы",
                        portuguese: "Португальский",
                        applyAnytime: "Применять в любое время",
                        Submit: "Отправить"
                    },
                    deadlineLabels: {
                        initDeadline: "Первоначальный срок подачи заявки",
                        second: "Второй раунд",
                        third: "Третий раунд",
                        special: "Специальный раунд",
                        initDeadlineSmall: "Первоначальное заявление",
                        secondSmall: "Второй раунд",
                        thirdSmall: "Третий раунд",
                        fourthSmall: "Четвертый / специальный раунд",
                        fifthSmall: "Пятый / cпециальный раунд",
                    },
                    apply: {
                        quickApply: "Быстрое нанесение",
                        description: "Начните процесс подачи заявки, уведомив университет о своем интересе к программе. Университет свяжется с вами и предоставит дальнейшую информацию о процессе поступления.",
                        firstName: "Имя",
                        lastName: "Фамилия",
                        middleNames: "Средние имена (по желанию)",
                        Email: "Электронная почта",
                        SelectYourCountry: "Выберите вашу страну",
                        WhatsCitizenship: "Каков ваш статус гражданина?",
                        TOS: "Я согласен с Условиями предоставления услуг и Соглашением о конфиденциальности",
                        marketing: "Я согласен с предложениями, связанными с моей заявкой",
                        Next: "Следующий"
                    },
                    home: {
                        notRelative: "Не является родственником гражданина ЕС",
                        relative: "Родственник гражданина ЕС",
                        tuitionNote: "Плата за обучение может быть ниже, если у вас есть родители или бабушки и дедушки, являющиеся гражданами ЕС.",
                        discoverPortugal: "Откройте для себя Португалию",
                        taglinePT1: "Учитесь в любом из 2500 с лишним учебных заведений",
                        taglinePT2: "программы",
                        sintra: "Синтра, Португалия",
                        studyInEnglish: "Обучение на английском языке",
                        studyInEnglishSubtext: "Откройте для себя программы бакалавриата и магистратуры, преподаваемые на английском языке",
                        learnPortuguese: "Изучайте португальский язык",
                        learnPortugueseSubtext: "Изучайте португальский язык в языковых школах и университетах",
                        housing: "Найти жилье",
                        housingSubtext: "Откройте для себя отличные дома, квартиры, комнаты и общежития",
                        sponsored: "(Спонсор)",
                        searchADegree: "Поиск программы или курса...",
                        ImA:"Я...",
                        welcome: "Welcome to Study"
                    },
                    navbar: {
                        Home: "Главная",
                        Universities: "Университеты",
                        Contact: "Связаться с"
                    },
                    institutions: {
                        subtext: "В Португалии 18 очных государственных университетов и 8 частных университетов..",
                        imageAltText: "Коимбра, Португалия фоновое изображение"
                    },
                    citizenship: {
                        nonEU: "Не гражданин ЕС",
                        EU: "Гражданин ЕС",
                        CPLP: "Гражданин КПЛП"
                    },
                    search: {
                        Citizenship: "Гражданство",
                        notRelativeShort: "Не родственник гражданина ЕС",
                        relative: "Родственник гражданина ЕС",
                        Filters: "Фильтры",
                        Language: "Язык",
                        error404: "Ошибка 404 - Программа не найдена",
                        Degree: "Степень"
                    },
                    languageOptions: {
                        Any: "Любой",
                        English: "Английский язык",
                        Portuguese: "Португальский язык",
                        French: "Французский язык"
                    },
                    degreeTypes: {
                        title: "Степень",
                        langProg: "Языковые программы",
                        Masters: "Магистратура",
                        Bachelors: "Бакалавриат",
                        DegreeIn: "Степень в",
                        BachelorsIn: "Бакалавр в",
                        MastersIn: "Магистр в",
                        PHDIn: "Доктор философии в",
                        FoundationYearIn: "Год основания в",
                        Nondegreecourse: "Курс без степени",
                        FoundationYear: "Foundation Year",
                        IntegratedMasters: "Интегрированные магистры",
                        LangP: "Языковая программа",
                        PHD: "Доктор философии",
                        in: "на"
                    },
                    contact: {
                        title: "Свяжитесь с нами",
                        name: "Имя",
                        email: "Электронная почта",
                        message: "Сообщение",
                        submit: "Отправить",
                        resultMessage: "Обычно мы отвечаем на электронные письма в течение 4 рабочих дней.",
                        resultMessageTitle: "Мы скоро с вами свяжемся"
                    }
                }
            }
        }
    });


export default i18n;